import React from 'react'
import Icon from './'

const Heap = props => (
  <Icon
    {...{
      fill: '#a70074',
      ...props
    }}
  >
    <path d="M61.23,21.21A8.58,8.58,0,0,0,57,14.75C49.93,10.42,43,6,36.45,2a6.38,6.38,0,0,0-6.9,0C23,6,16.07,10.45,9,14.75a8.63,8.63,0,0,0-4.21,6.46,165.32,165.32,0,0,0,0,21.58A8.58,8.58,0,0,0,9,49.25C16.07,53.55,23,58,29.55,62a6.38,6.38,0,0,0,6.9,0C43,58,49.93,53.55,57,49.25a8.5,8.5,0,0,0,4.21-6.46A165.32,165.32,0,0,0,61.23,21.21Zm-21-2.77H42.2a1.45,1.45,0,1,1,0,2.9H40.26a1.45,1.45,0,1,1,0-2.9Zm0,5.81h5.82a1.46,1.46,0,0,1,0,2.91H40.26a1.46,1.46,0,0,1,0-2.91Zm0,5.81h5.82a1.46,1.46,0,0,1,0,2.91H40.26a1.46,1.46,0,0,1,0-2.91Zm0,5.81H49a1.46,1.46,0,1,1,0,2.91H40.26a1.46,1.46,0,0,1,0-2.91ZM16.05,24.25h3.87a1.46,1.46,0,0,1,0,2.91H16.05a1.46,1.46,0,1,1,0-2.91Zm0,5.81H27.67a1.46,1.46,0,0,1,0,2.91H16.05a1.46,1.46,0,1,1,0-2.91Zm0,5.81H32.51a1.46,1.46,0,1,1,0,2.91H16.05a1.46,1.46,0,1,1,0-2.91ZM49,44.59H16.05a1.45,1.45,0,1,1,0-2.9H49a1.45,1.45,0,1,1,0,2.9Z" />
  </Icon>
)

export default Heap
